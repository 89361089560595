import React, { useContext, useReducer } from 'react'
import ISiteStateManager from './ISiteStateManager'
import {
    IMenuState,
    menuReducer,
    MenuReducerAction,
} from './reducers/MenuReducer'
import { MenuType } from '../../models/MenuType'
import { MenuOption } from '../../models/MenuOption'
import {
    AudioReducerAction,
    IAudioState,
    audioReducer,
} from './reducers/AudioReducer'

import {
    SideBarReducerAction,
    ISideBarState,
    sideBarReducer,
} from './reducers/SideBarReducer'

/**
 * Site state provider properties
 */
export interface SiteStateProviderProps {
    children?: JSX.Element | JSX.Element[] | string
    initialOptions: MenuOption[]
    topBarOptionId?: string
    leftBarOptionId?: string
    stateSideBar?: boolean
}

/**
 * Site state context
 */
const SiteStateContext = React.createContext<ISiteStateManager>(undefined!)

/**
 * Site state hook
 * @returns Site state context
 */
export function useSiteState(): ISiteStateManager {
    return useContext(SiteStateContext)
}

/**
 * Creates an state object
 * @param options Menu options
 * @returns State object
 */
function createState(
    options: MenuOption[],
    topBarOptionId?: string,
    leftBarOptionId?: string
) {
    const state: IMenuState = {
        options: options,
        selectedOptions: {},
    }

    if (!topBarOptionId) return state

    const topBarOption = options.find((o) => o.id === topBarOptionId)
    state.selectedOptions![MenuType.TopBar.toString()] = topBarOption

    if (!leftBarOptionId) return state

    const leftBarOption = topBarOption?.children?.find(
        (o) => o.id === leftBarOptionId
    )
    state.selectedOptions![MenuType.LeftBar.toString()] = leftBarOption

    return state
}

/**
 * Site state  provider
 * @returns Component
 */
export default function SiteStateProvider({
    children,
    initialOptions,
    topBarOptionId,
    leftBarOptionId,
    stateSideBar,
}: SiteStateProviderProps) {
    const initialState = createState(
        initialOptions,
        topBarOptionId,
        leftBarOptionId
    )
    const [menuState, dispatchMenu] = useReducer(menuReducer, initialState)
    const [sideBarState, dispatchSideBar] = useReducer(sideBarReducer, {
        stateSideBar: stateSideBar ? stateSideBar : false,
    })
    const [audioState, dispatchAudio] = useReducer(audioReducer, {
        muted: true,
    })

    // Context value
    const value: ISiteStateManager = {
        menu: {
            data: menuState,
            selectOption: (
                type: MenuType,
                index: number,
                callback?: (n: IMenuState, o: IMenuState) => void
            ) =>
                dispatchMenu({
                    type: MenuReducerAction.SELECT_OPTION,
                    value: { type, index },
                    callback,
                }),
            resetSelectedOptions: (
                callback?: (n: IMenuState, o: IMenuState) => void
            ) =>
                dispatchMenu({
                    type: MenuReducerAction.RESET_OPTIONS,
                    value: undefined,
                    callback,
                }),
        },
        audio: {
            data: audioState,
            toggleMute: (callback?: (s: IAudioState) => void) =>
                dispatchAudio({
                    type: AudioReducerAction.TOGGLE_MUTE,
                    callback,
                }),
        },
        stateSideBar: {
            data: sideBarState,
            collapseSideBar: () =>
                dispatchSideBar({
                    type: SideBarReducerAction.COLLAPSE,
                }),
            expandedSideBar: () =>
                dispatchSideBar({
                    type: SideBarReducerAction.EXPANDED,
                }),
        },
    }

    return (
        <SiteStateContext.Provider value={value}>
            {children}
        </SiteStateContext.Provider>
    )
}
